import { FormBuilder, FormGroup, Validators } from '@angular/forms';

export interface LoginFormData {
  tenantId: {
    id: number;
  };
  userId: string;
}

enum LoginFormKeys {
  tenantId = 'tenantId',
  id = 'id',
  userId = 'userId',
}

export class LoginForm {
  private formControl: FormGroup;

  constructor(private formBuilder: FormBuilder) {
    this.formControl = this.formBuilder.group({
      [LoginFormKeys.tenantId]: formBuilder.group({
        [LoginFormKeys.id]: ['', Validators.required],
      }),
      [LoginFormKeys.userId]: [''],
    });
  }

  getFormControl() {
    return this.formControl;
  }

  getForm(): LoginFormData {
    return this.formControl.value;
  }

  getTenantId(): LoginFormData['tenantId'] {
    return this.getFormControl().get(LoginFormKeys.tenantId)?.value;
  }
  getUserId(): string {
    return this.getFormControl().get(LoginFormKeys.userId)?.value;
  }
}
