import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Angular Material
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ListDocumentIdsComponent } from './components/list-document-ids/list-document-ids.component';
import { CreateDocumentComponent } from './components/create-document/create-document.component';
import { ViewDocumentComponent } from './components/view-document/view-document.component';
import { ListDatasetsComponent } from './components/list-datasets/list-datasets.component';
import { LoginComponent } from './components/login/login.component';
import { SessionService } from './services/session.service';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { MatSelectModule } from '@angular/material/select';
import { BasicDropdownItemsPipe } from './pipes/basic-dropdown-items.pipe';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { SettingsModalComponent } from './components/settings-modal/settings-modal.component';
import { ViewPermissionComponent } from './components/view-permission/view-permission.component';
import { PermissionCreatorModalComponent } from './components/permission-creator-modal/permission-creator-modal.component';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { initializeKeycloak } from './utility/keycloak.init';

@NgModule({
  declarations: [
    AppComponent,
    ListDocumentIdsComponent,
    CreateDocumentComponent,
    ViewDocumentComponent,
    ListDatasetsComponent,
    LoginComponent,
    DropdownComponent,
    BasicDropdownItemsPipe,
    ConfirmModalComponent,
    SettingsModalComponent,
    ViewPermissionComponent,
    PermissionCreatorModalComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatButtonModule,
    MatDialogModule,
    MatInputModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatTooltipModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatChipsModule,
    MatTabsModule,
    MatExpansionModule,
    KeycloakAngularModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
