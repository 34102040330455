import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { DatasetConfig } from 'src/app/model/dataset-config';
import { DatasetService } from 'src/app/services/dataset.service';
import { DocumentsService } from 'src/app/services/documents.service';
import { CreateDocumentComponent } from '../create-document/create-document.component';
import CreatePermissionModalData from 'src/app/model/create-permission-modal';
import { PermissionCreatorModalComponent } from '../permission-creator-modal/permission-creator-modal.component';
import { FalkorErrorResponse } from 'src/app/model/web/falkor-error-response';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { PermissionService } from 'src/app/services/permissions.service';
import { DocumentInfoResponse } from '../../model/web/document-info-response';
import { DocumentMetaDataResponse } from '../../model/web/document-meta-data-response';

@Component({
  selector: 'app-documents',
  templateUrl: './list-document-ids.component.html',
  styleUrls: ['./list-document-ids.component.scss'],
})
export class ListDocumentIdsComponent implements OnInit, OnDestroy {
  documentIds: string[] = [];
  permissionIds: number[] = [];
  showSpinner: boolean;
  datasetIdParam: string = '';
  datasetConfig?: DatasetConfig;
  documentInfos: DocumentInfoResponse = { timestamp: new Date(), version: 0 };
  documentMetaDataResponses: DocumentMetaDataResponse[] = [];
  body?: Observable<any>;
  documentBodyVisibility: boolean[] = [];
  private subscriptions = new Subscription();
  documentBodies: any[] = [];
  sortOrder: string = '';
  ascendingOrder: string = 'asc';
  descendingOrder: string = 'desc';
  sortType: string = '';

  constructor(
    private documentService: DocumentsService,
    private permissionService: PermissionService,
    private datasetService: DatasetService,
    private snackbarService: SnackbarService,
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute,
  ) {
    this.showSpinner = true;
  }
  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap;
    this.datasetIdParam = String(routeParams.get('datasetId'));

    this.subscriptions.add(
      this.datasetService.getDatasetInfo(this.datasetIdParam).subscribe((datasetConfig) => {
        this.datasetConfig = datasetConfig;
        if (this.datasetConfig.documentPermissions) {
          this.getPermissionIds(this.datasetIdParam!);
        }
      }),
    );

    this.subscriptions.add(
      this.documentService.getDocumentIds(this.datasetIdParam).subscribe((documentIdsArray) => {
        this.documentIds = documentIdsArray;
        this.sort(this.documentIds);
        this.showSpinner = false;
        this.documentIds.forEach((documentId) => {
          this.fetchData(documentId);
        });
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onHeaderClick(sortType: string) {
    if (this.sortType === sortType) {
      this.toggleSortOrder();
    } else {
      this.sortType = sortType;
      this.sortOrder = this.ascendingOrder;
    }
    this.sortDoc(this.sortType);
  }

  toggleSortOrder() {
    this.sortOrder = this.sortOrder === this.ascendingOrder ? this.descendingOrder : this.ascendingOrder;
  }

  sortDoc(sortType: string) {
    switch (sortType) {
      case 'document-id':
        this.documentMetaDataResponses.sort((a, b) => {
          const regex = /^\d+$/; // Regex pattern to match only digits
          const isNumericA = regex.test(a.documentId);
          const isNumericB = regex.test(b.documentId);

          if (isNumericA && isNumericB) {
            // Both IDs are numeric, parse and compare as integers
            return this.sortOrder === this.ascendingOrder
              ? parseInt(a.documentId, 10) - parseInt(b.documentId, 10)
              : parseInt(b.documentId, 10) - parseInt(a.documentId, 10);
          } else {
            // Handle non-numeric IDs by comparing them as strings
            return this.sortOrder === this.ascendingOrder
              ? a.documentId.localeCompare(b.documentId)
              : b.documentId.localeCompare(a.documentId);
          }
        });
        break;
      case 'timestamp':
        this.documentMetaDataResponses.sort((a, b) =>
          this.sortOrder === this.ascendingOrder
            ? new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
            : new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime(),
        );
        break;
      case 'version':
        this.documentMetaDataResponses.sort((a, b) =>
          this.sortOrder === this.ascendingOrder ? a.version - b.version : b.version - a.version,
        );
        break;
      case 'transaction-id':
        this.documentMetaDataResponses.sort((a, b) =>
          this.sortOrder === this.ascendingOrder
            ? a.transactionId.localeCompare(b.transactionId)
            : b.transactionId.localeCompare(a.transactionId),
        );
        break;
      default:
        break;
    }
  }

  openCreateDocumentDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      datasetId: this.datasetIdParam,
      datasetConfig: this.datasetConfig,
    };

    const dialogRef = this.dialog.open(CreateDocumentComponent, dialogConfig);

    this.subscriptions.add(
      dialogRef.afterClosed().subscribe((data) => {
        if (data.documentId) {
          this.documentIds.push(data.documentId);
          this.sort(this.documentIds);
        }
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/datasets', this.datasetIdParam]);
        });
      }),
    );
  }

  openCreatePermissionDialog() {
    const dialogConfig = new MatDialogConfig<CreatePermissionModalData>();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      datasetId: this.datasetIdParam!,
    };

    const dialogRef = this.dialog.open(PermissionCreatorModalComponent, dialogConfig);

    this.subscriptions.add(
      dialogRef.afterClosed().subscribe({
        next: (data) => {
          if (data) {
            this.snackbarService.openSnackBar(true, 'Permission created');
          }
        },
        error: (error: FalkorErrorResponse) => {
          this.snackbarService.openSnackBar(false, error.message);
        },
      }),
    );
  }

  navigateToDocument(documentId: string) {
    this.router.navigate(['/documents', documentId], {
      queryParams: {
        datasetId: this.datasetIdParam,
      },
    });
  }

  showDocumentBody(documentId: string, index: number) {
    const documentVersions = this.documentMetaDataResponses.filter((doc) => doc.documentId === documentId);

    if (documentVersions.length > 0) {
      documentVersions.sort((a, b) => b.version - a.version);
      const latestVersion = documentVersions[0].version;
      this.documentService.getDocumentBody(this.datasetIdParam, documentId, latestVersion).subscribe((bodyData) => {
        this.documentBodyVisibility[index] = !this.documentBodyVisibility[index];
        this.documentBodies[index] = bodyData;
      });
    }
  }

  navigateToPermission(permissionId: number) {
    this.router.navigate(['/permissions', permissionId], {
      queryParams: {
        datasetId: this.datasetIdParam,
      },
    });
  }

  sort(documentIds: string[]) {
    return documentIds.sort(this.numericalSort);
  }

  numericalSort(a: string, b: string): number {
    return Number(b) - Number(a);
  }

  getPermissionIds(datasetId: string) {
    this.permissionService.getPermissionIds(datasetId).subscribe({
      next: (permissionIds) => {
        this.permissionIds = permissionIds;
      },
      error: (error: FalkorErrorResponse) => {
        this.snackbarService.openSnackBar(false, error.message);
      },
    });
  }

  fetchData(documentId: string) {
    this.subscriptions.add(
      this.documentService.getDocumentInfo(this.datasetIdParam, documentId).subscribe((documentInfoResponse) => {
        this.documentInfos = documentInfoResponse;
        const response: DocumentMetaDataResponse = {
          version: documentInfoResponse.version,
          timestamp: documentInfoResponse.timestamp,
          documentId: documentId,
          //TODO: This hardcoded txnid value needs to be replaced
          transactionId: 'b0d7905',
        };
        this.documentMetaDataResponses.push(response);
      }),
    );
  }
}
