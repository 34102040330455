<body>
  <mat-card>
    <mat-card-title>Enter Credentials</mat-card-title>

    <form [formGroup]="loginForm.getFormControl()" (ngSubmit)="submit()">
      <div fxLayout="row">
        <mat-form-field formGroupName="tenantId">
          <input matInput type="number" placeholder="Tenant ID" formControlName="id" />
        </mat-form-field>
      </div>

      <div fxLayout="row">
        <mat-form-field>
          <input matInput type="text" placeholder="User ID" formControlName="userId" />
        </mat-form-field>
      </div>

      <button mat-button type="submit" class="login-btn">Submit</button>
    </form>
  </mat-card>
</body>
