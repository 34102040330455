import { Pipe, PipeTransform } from '@angular/core';
import { DropdownItem } from '../model/dropdown-item';

@Pipe({
  name: 'basicDropdownItems',
})
export class BasicDropdownItemsPipe<T> implements PipeTransform {
  transform(values: T[]): DropdownItem<T>[] {
    return values.map((value) => {
      return {
        value: value,
        viewValue: String(value),
      };
    });
  }
}
