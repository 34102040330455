import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private snackBar: MatSnackBar) {}

  openSnackBar(isSuccess: boolean, message: string) {
    let config: MatSnackBarConfig = this.getSnackbarConfig(isSuccess);

    this.snackBar.open(message, 'x', config);
  }

  private getSnackbarConfig(isSuccess: boolean): MatSnackBarConfig {
    let config: MatSnackBarConfig = new MatSnackBarConfig();
    config.duration = 5000;
    config.verticalPosition = 'top';
    config.panelClass = isSuccess ? 'snack-bar-success' : 'snack-bar-error';

    return config;
  }
}
