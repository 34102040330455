<mat-toolbar class="company-primary">
  <mat-toolbar-row>
    <nav *ngIf="isAuthenticated()">
      <a routerLink="/datasets" routerLinkActive="active-link">Datasets</a>
    </nav>
    <span class="example-spacer"></span>
    <mat-icon *ngIf="isAuthenticated()" [matMenuTriggerFor]="menu" class="example-icon white-icon" aria-hidden="false">
      account_circle
    </mat-icon>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="logout()">Logout</button>
    </mat-menu>
  </mat-toolbar-row>
</mat-toolbar>

<router-outlet></router-outlet>

<footer>
  <p>Copyright © 2022 - ByzGen Ltd</p>
</footer>
