import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DocumentsService } from 'src/app/services/documents.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { CreateDocumentResponse } from 'src/app/model/web/create-document-response';
import { FalkorErrorResponse } from 'src/app/model/web/falkor-error-response';
import { DatasetConfig } from 'src/app/model/dataset-config';
import { Tags } from 'src/app/model/tags';
import { CreateFalkorDocumentBuilder } from 'src/app/model/create-falkor-document';
import { CreateDocumentForm, CreateDocumentFormBuilder } from 'src/app/model/form/create-document-form';
import { Link } from 'src/app/model/link';
import { Observable, Subscription, catchError, of, tap } from 'rxjs';
import { PermissionService } from 'src/app/services/permissions.service';

@Component({
  selector: 'app-create-document',
  templateUrl: './create-document.component.html',
  styleUrls: ['./create-document.component.scss'],
})
export class CreateDocumentComponent implements OnInit, OnDestroy {
  datasetId: string;
  datasetConfig: DatasetConfig;
  createDocumentForm: CreateDocumentForm;
  showSpinner: boolean;
  links: Array<Link> = [];
  tags?: Tags;
  permissionIds?: Array<number>;

  private subscriptions?: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private documentService: DocumentsService,
    private permissionService: PermissionService,
    private snackbarService: SnackbarService,
    private dialogRef: MatDialogRef<CreateDocumentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.subscriptions = new Subscription();
    this.datasetId = data.datasetId;
    this.datasetConfig = data.datasetConfig;

    const createDocumentFormBuilder = new CreateDocumentFormBuilder(formBuilder);

    if (this.datasetConfig?.documentTags) {
      this.tags = {};
      createDocumentFormBuilder.withTags();
    }

    if (this.datasetConfig?.documentPermissions) {
      createDocumentFormBuilder.withPermissions();
    }
    this.createDocumentForm = createDocumentFormBuilder.build();

    this.showSpinner = false;
  }

  ngOnInit(): void {
    if (this.datasetConfig.documentPermissions) {
      this.subscriptions?.add(
        this.permissionService.getPermissionIds(this.datasetId).subscribe({
          next: (response) => {
            this.permissionIds = response;
          },
          error: (error: FalkorErrorResponse) => {
            this.snackbarService.openSnackBar(false, error.message);
          },
        }),
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions?.unsubscribe();
  }

  toggleAutoIncrement() {
    const documentIdControl = this.createDocumentForm.getDocumentIdControl();

    if (documentIdControl?.enabled) {
      documentIdControl.disable();
      documentIdControl.setValue('');
    } else {
      documentIdControl?.enable();
    }
  }

  addTag() {
    if (this.tags) {
      const tagKey = this.createDocumentForm.getTagKey();
      const tagValue = this.createDocumentForm.getTagValue();

      if (!tagKey || !tagValue) {
        this.snackbarService.openSnackBar(false, 'Key and Value fields cannot be empty.');
        return;
      }

      this.tags[tagKey] = tagValue;

      this.createDocumentForm.setTagKey('');
      this.createDocumentForm.setTagValue('');
    }
  }

  deleteTag(key: string) {
    if (this.tags) {
      delete this.tags[key];
    }
  }

  addLink() {
    const datasetId = this.createDocumentForm.getLinkDatasetId();
    const documentId = this.createDocumentForm.getLinkDocumentId();

    if (!datasetId || !documentId) {
      this.snackbarService.openSnackBar(false, 'Dataset ID and Document ID fields cannot be empty.');
      return;
    }

    this.links.push(this.createDocumentForm.getLink());

    this.createDocumentForm.setLinkDatasetId('');
    this.createDocumentForm.setLinkDocumentId('');
  }

  deleteLink(linkToDelete: Link) {
    this.links = this.links.filter((link) => link !== linkToDelete);
  }

  selectPermission(permissionId: number) {
    this.createDocumentForm.setPermissionId(permissionId);
  }

  createDocument() {
    this.showSpinner = true;
    const createDocumentFormData = this.createDocumentForm.getForm();
    const createFalkorDocumentBuilder = new CreateFalkorDocumentBuilder(createDocumentFormData.data);
    if (this.datasetConfig?.documentTags && this.tags && Object.keys(this.tags).length > 0) {
      createFalkorDocumentBuilder.withTags(this.tags);
    }

    if (createDocumentFormData.documentId) {
      createFalkorDocumentBuilder.withDocumentId(createDocumentFormData.documentId);
    }

    if (this.links.length > 0) {
      createFalkorDocumentBuilder.withLinks(this.links);
    }

    if (createDocumentFormData.permissionId) {
      createFalkorDocumentBuilder.withPermissionId(createDocumentFormData.permissionId);
    }

    const createFalkorDocument = createFalkorDocumentBuilder.build();
    this.subscriptions?.add(
      this.documentService.createDocument(createFalkorDocument, this.datasetId).subscribe({
        next: (response) => {
          this.snackbarService.openSnackBar(true, this.getSuccessMessage(response));

          // Return result to parent component
          this.dialogRef.close({ documentId: response.documentId, transactionId: response.transactionId });
        },
        error: (errorResponse: HttpErrorResponse) => {
          this.snackbarService.openSnackBar(false, this.getErrorMessage(errorResponse.error));
          this.dialogRef.close();
        },
      }),
    );
  }

  private getSuccessMessage(response: CreateDocumentResponse): string {
    return `Success! Document ${response.documentId} was created.`;
  }

  private getErrorMessage(response: FalkorErrorResponse): string {
    return `Error! ${response.message}`;
  }
}
