import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { CreateDocumentResponse } from '../model/web/create-document-response';
import { DocumentInfoResponse } from '../model/web/document-info-response';
import Audit from '../model/audit';
import { UpdateDocumentResponse } from '../model/web/update-document-response';
import { AuthenticationService } from './authentication.service';
import { CreateFalkorDocument } from '../model/create-falkor-document';

@Injectable({ providedIn: 'root' })
export class DocumentsService {
  private baseUrl: string;

  constructor(private http: HttpClient, private authService: AuthenticationService) {
    this.baseUrl = `/api/core/v0/${this.authService.credentials?.tenantId}/dataset`;
  }
  getDocumentIds(datasetId: string): Observable<string[]> {
    const getDocumentsUrl = `${this.baseUrl}/${datasetId}/`;

    return this.http.get<string[]>(getDocumentsUrl, {
      headers: this.authService.getHttpHeaders(),
    });
  }

  createDocument(document: CreateFalkorDocument, datasetId: string): Observable<CreateDocumentResponse> {
    const createDocumentsUrl = `${this.baseUrl}/${datasetId}/create`;

    return this.http.post<CreateDocumentResponse>(createDocumentsUrl, document, {
      headers: this.authService.getHttpHeaders(),
    });
  }

  getDocumentBody(datasetId: string, documentId: string, version?: number): Observable<any> {
    const viewDocumentUrl = `${this.baseUrl}/${datasetId}/${documentId}/body`;

    let params = new HttpParams();

    if (version) {
      params = params.append('version', String(version));
    }

    return this.http.get(viewDocumentUrl, {
      headers: this.authService.getHttpHeaders(),
      params: params,
      responseType: 'text',
    });
  }

  updateDocumentBody(documentBody: string, datasetId: string, documentId: string): Observable<UpdateDocumentResponse> {
    const updateDocumentsUrl = `${this.baseUrl}/${datasetId}/${documentId}/body`;

    return this.http.put<UpdateDocumentResponse>(updateDocumentsUrl, documentBody, {
      headers: this.authService.getHttpHeaders().set('content-type', 'application/octet-stream'),
    });
  }

  getDocumentInfo(datasetId: string, documentId: string): Observable<DocumentInfoResponse> {
    const documentInfoUrl = `${this.baseUrl}/${datasetId}/${documentId}/info`;

    return this.http.get<DocumentInfoResponse>(documentInfoUrl, {
      headers: this.authService.getHttpHeaders(),
    });
  }
}
