import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Version } from 'src/app/model/version';
import {
  ViewDocumentSettingsModalInput,
  ViewDocumentSettingsModalOutput,
} from 'src/app/model/view-document-settings-modal';

@Component({
  selector: 'app-settings-modal',
  templateUrl: './settings-modal.component.html',
  styleUrls: ['./settings-modal.component.scss'],
})
export class SettingsModalComponent implements OnInit, OnDestroy {
  canWrite: boolean;

  editMode: boolean;
  editToggleEnabled: boolean;

  bodyVersions: Version;
  selectedBodyVersion: BehaviorSubject<number>;

  linkVersions?: Version;
  selectedLinkVersion: BehaviorSubject<number>;

  tagVersions?: Version;
  selectedTagVersion: BehaviorSubject<number>;

  subscriptions: Subscription = new Subscription();

  constructor(
    private dialogRef: MatDialogRef<SettingsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ViewDocumentSettingsModalInput,
  ) {
    this.bodyVersions = data.bodyVersions;
    this.selectedBodyVersion = new BehaviorSubject<number>(data.selectedBodyVersion);

    this.linkVersions = data.linkVersions;
    this.selectedLinkVersion = new BehaviorSubject<number>(data.selectedLinkVersion);

    this.tagVersions = data.tagVersions;
    this.selectedTagVersion = new BehaviorSubject<number>(data.selectedTagVersion);

    this.editMode = data.editMode;
    this.editToggleEnabled = this.canEdit();
    this.canWrite = data.canWrite;
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.selectedBodyVersion.subscribe(() => {
        this.disableEditMode();
      }),
    );

    this.subscriptions.add(
      this.selectedLinkVersion.subscribe(() => {
        this.disableEditMode();
      }),
    );

    this.subscriptions.add(
      this.selectedTagVersion.subscribe(() => {
        this.disableEditMode();
      }),
    );
  }

  canEdit(): boolean {
    return (
      this.selectedBodyVersion.value === this.bodyVersions.getLatestVersion() &&
      (!this.linkVersions || this.selectedLinkVersion.value === this.linkVersions.getLatestVersion()) &&
      (!this.tagVersions || this.selectedTagVersion.value === this.tagVersions.getLatestVersion()) &&
      this.canWrite
    );
  }

  disableEditMode() {
    this.editToggleEnabled = this.canEdit();
    if (!this.editToggleEnabled) {
      this.editMode = false;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  selectBodyVersion(version: number) {
    this.selectedBodyVersion.next(version);
  }

  selectLinkVersion(version: number) {
    this.selectedLinkVersion.next(version);
  }

  selectTagVersion(version: number) {
    this.selectedTagVersion.next(version);
  }

  save() {
    const result: ViewDocumentSettingsModalOutput = {
      editMode: this.editMode,
      bodyVersion: this.selectedBodyVersion.value,
      linkVersion: this.selectedLinkVersion.value,
      tagVersion: this.selectedTagVersion.value,
    };
    this.dialogRef.close(result);
  }
}
