import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { SessionService } from './services/session.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'falkor-frontend';

  constructor(private authService: KeycloakService, private router: Router, private sessionService: SessionService) {}

  logout() {
    this.authService.logout();
    this.sessionService.clear();
    this.router.navigate(['/']);
  }

  isAuthenticated() {
    return this.authService.isLoggedIn();
  }
}
