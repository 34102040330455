import { Link } from './link';
import { Version } from './version';

export class DocumentLinksInfo extends Version {
  private links?: Array<Link>;

  getLinks() {
    return this.links;
  }

  setLinks(links: Array<Link>) {
    this.links = links;
  }
}
