import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';
import { DocumentsService } from 'src/app/services/documents.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { FalkorDocument } from 'src/app/model/falkor-document';
import { DocumentInfoResponse } from 'src/app/model/web/document-info-response';
import Audit from 'src/app/model/audit';
import { UpdateDocumentResponse } from 'src/app/model/web/update-document-response';
import { FalkorErrorResponse } from 'src/app/model/web/falkor-error-response';
import { ActivatedRoute, Router } from '@angular/router';
import { TagsService } from 'src/app/services/tags.service';
import { DocumentTags } from 'src/app/model/document-tags';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { ConfirmModal } from 'src/app/model/confirm-modal';
import { UpdateDocumentForm } from 'src/app/model/form/update-document-form';
import { LinksService } from 'src/app/services/links.service';
import { DocumentLinksResponse } from 'src/app/model/web/document-links-response';
import { Link } from 'src/app/model/link';
import { DocumentLinksInfo } from 'src/app/model/document-links';
import { DocumentTagsResponse } from 'src/app/model/web/document-tags-response';
import { Subscription } from 'rxjs';
import { ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { SettingsModalComponent } from '../settings-modal/settings-modal.component';
import {
  ViewDocumentSettingsModalInput,
  ViewDocumentSettingsModalOutput,
} from 'src/app/model/view-document-settings-modal';
import { Permissions } from 'src/app/model/permission';
import { PermissionService } from 'src/app/services/permissions.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AuditService } from 'src/app/services/audit.service';
import AUDIT_TABLE_HEADERS from 'src/app/model/audit-table-headers';

enum ViewDocumentUrlParams {
  datasetId = 'datasetId',
  documentId = 'documentId',
}

type AuditTabName = 'Document' | 'Links';
interface AuditTab {
  name: AuditTabName;
  datasource: MatTableDataSource<Audit>;
}

@Component({
  selector: 'app-view-document',
  templateUrl: './view-document.component.html',
  styleUrls: ['./view-document.component.scss'],
})
export class ViewDocumentComponent implements OnInit, OnDestroy {
  datasetId!: string;
  document!: FalkorDocument;
  updateDocumentForm: UpdateDocumentForm;
  dialogRef?: MatDialogRef<any, any>;

  // Audit Tab sources
  auditTabs: AuditTab[] = [];

  // Audit Table datasources
  tableColumnHeaders: string[];
  documentDataSource: MatTableDataSource<Audit>;
  linkDataSource: MatTableDataSource<Audit>;

  // Ui state
  showSpinner: boolean;
  editMode: boolean;

  // Selectors
  selectedTagVersion: number = 1;
  selectedLinksVersion: number = 1;
  selectedBodyVersion: number = 1;

  // Audit trails
  documentAudit: Array<Audit> = [];
  linksAudit: Array<Audit> = [];

  // Permissions
  canRead: boolean = false;
  canWrite: boolean = false;

  // Optional Info
  documentTags?: DocumentTags;
  permissions?: Permissions;

  readonly separatorKeysCodes = [ENTER] as const;

  private subscriptions = new Subscription();

  constructor(
    private documentService: DocumentsService,
    private authService: AuthenticationService,
    private permissionsService: PermissionService,
    private tagsService: TagsService,
    private linksService: LinksService,
    private auditService: AuditService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private snackBarService: SnackbarService,
    private dialog: MatDialog,
  ) {
    // ngOnInit will not run when the same component is rerendered.
    // Need to subscribe to route parameter changes to rerun ngOnInit when navigating
    // between document links.
    this.subscriptions.add(
      route.params.subscribe(() => {
        this.ngOnInit();
      }),
    );

    this.tableColumnHeaders = AUDIT_TABLE_HEADERS;
    this.documentDataSource = new MatTableDataSource<Audit>();
    this.linkDataSource = new MatTableDataSource<Audit>();

    this.updateDocumentForm = new UpdateDocumentForm(formBuilder);

    this.auditTabs = [
      {
        name: 'Document',
        datasource: this.documentDataSource,
      },
      {
        name: 'Links',
        datasource: this.linkDataSource,
      },
    ];

    // Flags to control UI behaviour
    this.editMode = false;
    this.showSpinner = false;
  }

  ngOnInit(): void {
    const queryParams = this.route.snapshot.queryParamMap;

    const datasetIdParam = queryParams.get(ViewDocumentUrlParams.datasetId);
    if (!datasetIdParam) {
      this.snackBarService.openSnackBar(false, `${ViewDocumentUrlParams.datasetId} is not present in url`);
      this.router.navigateByUrl('/datasets');
      return;
    }
    this.datasetId = datasetIdParam;

    const routeParams = this.route.snapshot.paramMap;
    const documentIDParam = routeParams.get(ViewDocumentUrlParams.documentId);
    if (!documentIDParam) {
      this.snackBarService.openSnackBar(false, `${ViewDocumentUrlParams.datasetId} is not present in url`);
      this.router.navigateByUrl('/datasets');
      return;
    }

    this.document = new FalkorDocument(documentIDParam);
    this.setDocumentDetails();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private setDocumentDetails() {
    this.setDocumentInfo();
    this.setDocumentBody();
    this.setDocumentAudit();
    this.setLinksAudit();
  }

  private setDocumentInfo() {
    this.subscriptions.add(
      this.documentService.getDocumentInfo(this.datasetId, this.document.getId()).subscribe({
        next: (documentInfoResponse: DocumentInfoResponse) => {
          this.document.setVersions(documentInfoResponse.version);
          this.selectedBodyVersion = this.document.getLatestVersion();
          this.document.setTimestamp(documentInfoResponse.timestamp);
          if (documentInfoResponse.linkedDocumentInfo) {
            this.setDocumentLinksInfo(documentInfoResponse);
            this.setDocumentLinks();
          }

          if (documentInfoResponse.tagsData) {
            this.documentTags = new DocumentTags();
            this.setDocumentTags(documentInfoResponse.tagsData);
            this.selectedTagVersion = this.documentTags?.getLatestVersion();
          }

          if (documentInfoResponse.permissionId) {
            this.setDocumentPermissions(documentInfoResponse.permissionId);
          } else {
            this.canRead = true;
            this.canWrite = true;
          }
        },
        error: (errorResponse: HttpErrorResponse) => {
          const redirectUrl = `/datasets/${this.datasetId}`;
          if (errorResponse.status === 400) {
            this.redirectWithError(redirectUrl, 'You cannot view this document');
            return;
          }

          this.redirectWithError(redirectUrl, errorResponse.error.message);
        },
      }),
    );
  }

  private setDocumentBody(version?: number) {
    this.subscriptions.add(
      this.documentService.getDocumentBody(this.datasetId, this.document.getId(), version).subscribe({
        next: (documentBody: string) => {
          this.document.setBody(documentBody);
        },
        error: (errorResponse: HttpErrorResponse) => {
          this.redirectWithError('/datasets', errorResponse.error.message);
        },
      }),
    );
  }

  private setDocumentAudit() {
    this.subscriptions.add(
      this.auditService.getDocumentAuditLog(this.datasetId, this.document.getId()).subscribe({
        next: (documentAuditResponse: Array<Audit>) => {
          this.documentAudit = documentAuditResponse;
          this.documentDataSource.data = this.documentAudit;
        },
        error: (errorResponse: HttpErrorResponse) => {
          this.redirectWithError('/datasets', errorResponse.error.message);
        },
      }),
    );
  }

  private setLinksAudit() {
    this.subscriptions.add(
      this.auditService.getLinkAuditLog(this.datasetId, this.document.getId()).subscribe({
        next: (linksAuditResponse: Array<Audit>) => {
          this.linksAudit = linksAuditResponse;
          this.linkDataSource.data = this.linksAudit;
        },
        error: (errorResponse: HttpErrorResponse) => {
          if (errorResponse.error.httpStatus === 'NOT_FOUND') {
            this.linksAudit = [];
            return;
          }
          this.redirectWithError(`/datasets/${this.datasetId}`, errorResponse.error.message);
        },
      }),
    );
  }

  private setDocumentLinksInfo(documentInfoResponse: DocumentInfoResponse) {
    const documentLinksInfo = new DocumentLinksInfo();
    documentLinksInfo.setVersions(documentInfoResponse.linkedDocumentInfo!.version);
    documentLinksInfo.setTimestamp(documentInfoResponse.linkedDocumentInfo!.timestamp);
    this.document.setDocumentLinksInfo(documentLinksInfo);
    this.selectedLinksVersion = this.document.getDocumentLinksInfo()!.getLatestVersion();
  }

  private setDocumentLinks(version?: number) {
    this.subscriptions.add(
      this.linksService.getDocumentLinks(this.datasetId, this.document.getId(), version).subscribe({
        next: (documentLinksResponse: DocumentLinksResponse) => {
          this.document.getDocumentLinksInfo()?.setLinks(documentLinksResponse);
        },
        error: (errorResponse: HttpErrorResponse) => {
          this.redirectWithError('/datasets', errorResponse.error.message);
        },
      }),
    );
  }

  private setDocumentTagsInfo() {
    this.subscriptions.add(
      this.tagsService.getDocumentTagsInfo(this.datasetId, this.document.getId()).subscribe({
        next: (response) => {
          this.setDocumentTags(response);
          this.selectedTagVersion = this.documentTags!.getLatestVersion();
        },
        error: (errorResponse: HttpErrorResponse) => {
          this.redirectWithError('/datasets', errorResponse.error.message);
        },
      }),
    );
  }

  private setDocumentTags(tagsResponse: DocumentTagsResponse) {
    this.documentTags?.setTags(tagsResponse.tagsMap);
    this.documentTags?.setTimestamp(tagsResponse.tagsTimestamp);
    this.documentTags?.setVersions(tagsResponse.tagsVersion);
  }

  private setDocumentPermissions(permissionId: number) {
    const permissionDeniedMessage = 'You do not have permission to view this document';
    this.subscriptions.add(
      this.permissionsService.getPermission(this.datasetId, permissionId).subscribe({
        next: (permissions: Permissions) => {
          this.permissions = permissions;
          this.canRead = this.isReader(this.permissions);
          this.canWrite = this.isWriter(this.permissions);
          if (!this.canRead) {
            this.redirectWithError(`/datasets/${this.datasetId}`, permissionDeniedMessage);
          }
        },
        error: (errorResponse: HttpErrorResponse) => {
          const redirectUrl = `/datasets/${this.datasetId}`;
          this.redirectWithError(redirectUrl, errorResponse.error.message);
        },
      }),
    );
  }

  openUpdateDocumentConfirm() {
    const dialogConfig = new MatDialogConfig<ConfirmModal>();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Update Document',
      message: `Are you sure you want to update the document?`,
      action: () => this.updateDocument(),
    };

    this.dialogRef = this.dialog.open(ConfirmModalComponent, dialogConfig);
  }

  updateDocument() {
    this.showSpinner = true;
    this.subscriptions.add(
      this.documentService
        .updateDocumentBody(this.updateDocumentForm.getForm(), this.datasetId, this.document.getId())
        .subscribe({
          next: (response: UpdateDocumentResponse) => {
            console.log(`Create txn ID: ${response.transactionId}`);
            this.showSpinner = false;
            this.setDocumentInfo();

            const successMessage = `Success! Document was updated.`;
            this.snackBarService.openSnackBar(true, successMessage);
          },
          error: (errorResponse: HttpErrorResponse) => {
            this.snackBarService.openSnackBar(false, this.getErrorMessage(errorResponse.error));
          },
          complete: () => {
            this.dialogRef?.close();
          },
        }),
    );
  }

  openSettingsDialog() {
    const dialogConfig = new MatDialogConfig<ViewDocumentSettingsModalInput>();
    dialogConfig.data = {
      canWrite: this.canWrite,
      editMode: this.editMode,
      bodyVersions: this.document,
      selectedBodyVersion: this.selectedBodyVersion,
      linkVersions: this.document.getDocumentLinksInfo(),
      selectedLinkVersion: this.selectedLinksVersion,
      tagVersions: this.documentTags,
      selectedTagVersion: this.selectedTagVersion,
    };
    this.dialogRef = this.dialog.open(SettingsModalComponent, dialogConfig);
    this.subscriptions.add(
      this.dialogRef?.afterClosed().subscribe((data: ViewDocumentSettingsModalOutput) => {
        if (!data) {
          return;
        }

        this.checkForSettingChanges(data);
      }),
    );
  }

  checkForSettingChanges(data: ViewDocumentSettingsModalOutput) {
    const { bodyVersion, linkVersion, tagVersion, editMode } = data;
    if (this.selectedBodyVersion !== bodyVersion) {
      this.selectDocumentVersion(bodyVersion);
    }

    if (this.selectedLinksVersion !== linkVersion) {
      this.selectLinkVersion(linkVersion);
    }

    if (this.selectedTagVersion !== tagVersion) {
      this.selectTagVersion(tagVersion);
    }

    if (this.editMode !== editMode) {
      this.editMode = editMode;
    }
  }

  openTagConfirmDialog(dialogConfig: MatDialogConfig) {
    this.dialogRef = this.dialog.open(ConfirmModalComponent, dialogConfig);
    this.subscriptions.add(
      this.dialogRef.afterClosed().subscribe({
        next: () => {
          this.setDocumentTagsInfo();
        },
        error: (errorResponse: HttpErrorResponse) => {
          this.snackBarService.openSnackBar(false, errorResponse.message);
        },
      }),
    );
  }

  openAddTagDialog(event: MatChipInputEvent) {
    const [key, value] = event.value.split(':');
    if (!key || !value) {
      this.snackBarService.openSnackBar(false, 'Key and Value fields cannot be empty.');
      return;
    }

    const dialogConfig = new MatDialogConfig<ConfirmModal>();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Add tag',
      message: `Are you sure you want to add the tag ${key} of value ${value}?`,
      action: () => this.addTag(key, value),
    };

    this.openTagConfirmDialog(dialogConfig);
    event.chipInput!.clear();
  }

  addTag(key: string, value: string) {
    this.subscriptions.add(
      this.tagsService.updateDocumentTag(this.datasetId, this.document.getId(), key, value).subscribe({
        next: (response) => {
          this.dialogRef?.close(response);
        },
        error: (errorResponse: HttpErrorResponse) => {
          this.snackBarService.openSnackBar(false, errorResponse.message);
        },
        complete: () => {
          if (this.documentTags) {
            const latestVersion = this.documentTags.getLatestVersion();
            this.setDocumentTagsByVersion(latestVersion);
          }
        },
      }),
    );
  }

  openDeleteTagDialog(key: string, value: string) {
    const dialogConfig = new MatDialogConfig<ConfirmModal>();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Add tag',
      message: `Are you sure you want to delete the tag ${key} of value ${value}?`,
      action: () => this.deleteTag(key, value),
    };

    this.openTagConfirmDialog(dialogConfig);
  }

  deleteTag(key: string, value: string) {
    this.subscriptions.add(
      this.tagsService.deleteDocumentTag(this.datasetId, this.document.getId(), key, value).subscribe({
        next: (response) => {
          this.dialogRef?.close(response);
        },
        error: (errorResponse: HttpErrorResponse) => {
          this.snackBarService.openSnackBar(false, errorResponse.message);
        },
      }),
    );
  }

  openLinkConfirmDialog(dialogConfig: MatDialogConfig) {
    this.dialogRef = this.dialog.open(ConfirmModalComponent, dialogConfig);
    this.subscriptions.add(
      this.dialogRef.afterClosed().subscribe({
        next: () => {
          this.setDocumentInfo();
        },
        error: (errorResponse: HttpErrorResponse) => {
          this.snackBarService.openSnackBar(false, errorResponse.message);
        },
      }),
    );
  }

  openAddLinkDialog(event: MatChipInputEvent) {
    const [datasetId, documentId] = event.value.split('/');

    if (!datasetId || !documentId) {
      this.snackBarService.openSnackBar(
        false,
        'Dataset ID and Document ID fields cannot be empty. Make sure format is <dataset ID>/<document ID>',
      );
      return;
    }

    const dialogConfig = new MatDialogConfig<ConfirmModal>();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Add link',
      message: `Are you sure you want to add the link ${datasetId}/${documentId}?`,
      action: () => this.addLink({ datasetId, documentId }),
    };

    this.openLinkConfirmDialog(dialogConfig);
    event.chipInput!.clear();
  }

  addLink(link: Link) {
    this.subscriptions.add(
      this.linksService.updateDocumentLinks(this.datasetId, this.document.getId(), [link]).subscribe({
        next: (response) => {
          this.dialogRef?.close(response);
        },
        error: (errorResponse: HttpErrorResponse) => {
          this.snackBarService.openSnackBar(false, errorResponse.message);
        },
      }),
    );
  }

  openDeleteLinkDialog(link: Link) {
    const dialogConfig = new MatDialogConfig<ConfirmModal>();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Delete link',
      message: `Are you sure you want to delete the link ${link.datasetId}/${link.documentId}?`,
      action: () => this.deleteLink(link),
    };

    this.openLinkConfirmDialog(dialogConfig);
  }

  deleteLink(link: Link) {
    this.subscriptions.add(
      this.linksService.deleteDocumentLinks(this.datasetId, this.document.getId(), link).subscribe({
        next: (response) => {
          this.setDocumentInfo();
          this.dialogRef?.close(response);
        },
        error: (errorResponse: HttpErrorResponse) => {
          this.snackBarService.openSnackBar(false, errorResponse.message);
        },
      }),
    );
  }

  private setDocumentTagsByVersion(version: number) {
    this.subscriptions.add(
      this.tagsService.getDocumentTagsInfo(this.datasetId, this.document.getId(), version).subscribe({
        next: (response) => {
          this.documentTags?.setTags(response.tagsMap);
          this.documentTags?.setTimestamp(response.tagsTimestamp);
        },
        error: (errorResponse: HttpErrorResponse) => {
          this.snackBarService.openSnackBar(false, errorResponse.message);
        },
      }),
    );
  }

  auditLogHasEntries(auditLog: Array<Audit>): boolean {
    return auditLog.length > 0;
  }

  selectDocumentVersion(version: number) {
    this.setDocumentBody(version);
    this.selectedBodyVersion = version;
  }

  selectTagVersion(version: number) {
    this.setDocumentTagsByVersion(version);
    this.selectedTagVersion = version;
  }

  selectLinkVersion(version: number) {
    this.setDocumentLinks(version);
    this.selectedLinksVersion = version;
  }

  private getErrorMessage(response: FalkorErrorResponse): string {
    return `Error! ${response.message}`;
  }

  private redirectWithError(url: string, message: string) {
    this.router.navigateByUrl(url);
    this.snackBarService.openSnackBar(false, message);
  }

  private isReader(permissions: Permissions) {
    return permissions.readers.includes(this.authService.credentials!.userId!);
  }

  private isWriter(permissions: Permissions) {
    return permissions.writers.includes(this.authService.credentials!.userId!);
  }
}
