<h2 mat-dialog-title>Create Document</h2>

<mat-dialog-content [formGroup]="createDocumentForm.getFormControl()">
  <p>Create a document for the current dataset.</p>

  <div>
    <mat-form-field>
      <input matInput type="text" placeholder="Document ID" formControlName="documentId" [readonly]="showSpinner" />
    </mat-form-field>
    <mat-slide-toggle (change)="toggleAutoIncrement()">Auto increment</mat-slide-toggle>
    <ng-container *ngIf="datasetConfig.documentPermissions && permissionIds">
      <div>
        <app-dropdown
          appearance="standard"
          dropdownLabel="Permission Object ID"
          [dropdownItems]="permissionIds | basicDropdownItems"
          [selectedItem]="this.createDocumentForm.getPermissionId()"
          (newSelectedEvent)="selectPermission($event)"
        >
        </app-dropdown>
      </div>
    </ng-container>
    <mat-form-field class="document-body-field">
      <textarea
        matInput
        class="document-body-textarea"
        placeholder="Document Body"
        formControlName="data"
        [readonly]="showSpinner"
      ></textarea>
    </mat-form-field>
  </div>

  <h3>Links</h3>
  <div *ngFor="let link of links">
    <mat-form-field>
      <input matInput type="text" [value]="link.datasetId" readonly />
    </mat-form-field>
    <mat-form-field>
      <input matInput type="text" [value]="link.documentId" readonly />
    </mat-form-field>
    <button mat-button (click)="deleteLink(link)">Delete link</button>
  </div>
  <div formGroupName="link">
    <mat-form-field>
      <input matInput type="text" placeholder="Dataset ID" formControlName="datasetId" />
    </mat-form-field>
    <mat-form-field>
      <input matInput type="text" placeholder="Document ID" formControlName="documentId" />
    </mat-form-field>
    <button mat-button (click)="addLink()">Add Link</button>
  </div>
  <ng-container *ngIf="datasetConfig?.documentTags">
    <h3>Tags</h3>
    <div *ngFor="let tag of tags | keyvalue">
      <mat-form-field>
        <input matInput type="text" [value]="tag.key" readonly />
      </mat-form-field>
      <mat-form-field>
        <input matInput type="text" [value]="tag.value" readonly />
      </mat-form-field>
      <button mat-button (click)="deleteTag(tag.key)">Delete tag</button>
    </div>
    <div formGroupName="tags">
      <mat-form-field>
        <input matInput type="text" placeholder="Key" formControlName="key" />
      </mat-form-field>
      <mat-form-field>
        <input matInput type="text" placeholder="Value" formControlName="value" />
      </mat-form-field>
      <button mat-button (click)="addTag()">Add Tag</button>
    </div>
  </ng-container>
</mat-dialog-content>

<div *ngIf="showSpinner" class="center-spinner">
  <mat-spinner></mat-spinner>
</div>

<mat-dialog-actions>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button mat-button (click)="createDocument()" class="create-btn">Create</button>
  <button mat-button mat-dialog-close class="cancel-btn">Cancel</button>
</mat-dialog-actions>
