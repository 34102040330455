<mat-card>
  <mat-card-header>
    <mat-card-title>Permission {{ permissionId }}</mat-card-title>
    <mat-card-subtitle> Permission object for dataset {{ datasetId }} </mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>
    <div fxLayout="column" fxLayoutGap="1em">
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title> Audit Trail </mat-panel-title>
          <mat-panel-description> Click to view audit trail. </mat-panel-description>
        </mat-expansion-panel-header>
        <table mat-table [dataSource]="permissionDataSource">
          <ng-container matColumnDef="event">
            <th mat-header-cell *matHeaderCellDef>Event</th>
            <td mat-cell *matCellDef="let audit">
              <strong [className]="audit.event === 'create' ? 'create-event' : 'update-event'">
                {{ audit.event }}
              </strong>
            </td>
          </ng-container>
          <ng-container matColumnDef="version">
            <th mat-header-cell *matHeaderCellDef>Version</th>
            <td mat-cell *matCellDef="let audit">
              {{ audit.version }}
            </td>
          </ng-container>
          <ng-container matColumnDef="timestamp">
            <th mat-header-cell *matHeaderCellDef>Timestamp</th>
            <td mat-cell *matCellDef="let audit">
              <em>{{ audit.timestamp | date : 'medium' }}</em>
            </td>
          </ng-container>
          <ng-container matColumnDef="transactionId">
            <th mat-header-cell *matHeaderCellDef>Transaction ID</th>
            <td mat-cell *matCellDef="let audit" [matTooltip]="audit.transactionId" matTooltipPosition="left">
              {{ audit.transactionId.substr(0, 7) }}
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="tableColumnHeaders"></tr>
          <tr mat-row *matRowDef="let row; columns: tableColumnHeaders"></tr>
        </table>
      </mat-expansion-panel>
      <div fxLayout="column" fxLayoutGap="1em">
        <mat-form-field *ngFor="let role of permissions | keyvalue" class="form-field" appearance="outline">
          <mat-label>{{ role.key }}</mat-label>
          <mat-chip-list #chipList aria-label="list of user Ids">
            <mat-chip *ngFor="let userId of role.value" (removed)="openConfirmDeleteDialog(role.key, userId)">
              {{ userId }}
              <button matChipRemove>
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip>
            <input
              placeholder="Add user..."
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              (matChipInputTokenEnd)="openConfirmAddDialog(role.key, $event)"
            />
          </mat-chip-list>
        </mat-form-field>
      </div>
    </div>
  </mat-card-content>
</mat-card>
