export class DatasetTableRow {
  datasetId: string;
  encryptionType: string;
  externalStorage: boolean;
  documentPermissions: boolean;
  documentTags: boolean;
  linkedContract: string;

  constructor(
    datasetId: string,
    encryptionType: string,
    externalStorage: boolean,
    documentPermissions: boolean,
    documentTags: boolean,
    linkedContract: string,
  ) {
    this.datasetId = datasetId;
    this.encryptionType = encryptionType;
    this.externalStorage = externalStorage;
    this.documentPermissions = documentPermissions;
    this.documentTags = documentTags;
    this.linkedContract = linkedContract;
  }
}
