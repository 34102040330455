<mat-card>
  <mat-card-title>Dataset Configurations</mat-card-title>
  <mat-card-subtitle> List of all dataset configurations that belong to tenant {{ tenantId }} </mat-card-subtitle>
  <div *ngIf="showSpinner" class="center-spinner spinner-padding">
    <mat-spinner [diameter]="80"></mat-spinner>
  </div>
  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="datasetId">
      <th mat-header-cell *matHeaderCellDef>ID</th>
      <td mat-cell *matCellDef="let datasetConfig">
        {{ datasetConfig.datasetId }}
      </td>
    </ng-container>

    <ng-container matColumnDef="encryptionType">
      <th mat-header-cell *matHeaderCellDef>Encryption Type</th>
      <td mat-cell *matCellDef="let datasetConfig">
        <strong [className]="getCssClass(datasetConfig.encryptionType)">
          {{ datasetConfig.encryptionType }}
        </strong>
      </td>
    </ng-container>

    <ng-container matColumnDef="externalStorage">
      <th mat-header-cell *matHeaderCellDef>Offchain Storage</th>
      <td mat-cell *matCellDef="let datasetConfig">
        <mat-icon *ngIf="datasetConfig.externalStorage" aria-hidden="false" class="green-icon"> check </mat-icon>
        <mat-icon *ngIf="!datasetConfig.externalStorage" aria-hidden="false" class="red-icon"> close </mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="documentPermissions">
      <th mat-header-cell *matHeaderCellDef>Document Permissions</th>
      <td mat-cell *matCellDef="let datasetConfig">
        <mat-icon *ngIf="datasetConfig.documentPermissions" aria-hidden="false" class="green-icon"> check </mat-icon>
        <mat-icon *ngIf="!datasetConfig.documentPermissions" aria-hidden="false" class="red-icon"> close </mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="documentTags">
      <th mat-header-cell *matHeaderCellDef>Document Tags</th>
      <td mat-cell *matCellDef="let datasetConfig">
        <mat-icon *ngIf="datasetConfig.documentTags" aria-hidden="false" class="green-icon"> check </mat-icon>
        <mat-icon *ngIf="!datasetConfig.documentTags" aria-hidden="false" class="red-icon"> close </mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="linkedContract">
      <th mat-header-cell *matHeaderCellDef>Linked Contract</th>
      <td mat-cell *matCellDef="let datasetConfig">
        {{ datasetConfig.linkedContract }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" [routerLink]="['./', row.datasetId]"></tr>
  </table>
</mat-card>
