import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Inject, OnDestroy } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import PermissionCreatorModalData from 'src/app/model/create-permission-modal';
import { Permissions, Role } from 'src/app/model/permission';
import { FalkorErrorResponse } from 'src/app/model/web/falkor-error-response';
import { PermissionService } from 'src/app/services/permissions.service';

@Component({
  selector: 'app-create-permission-modal',
  templateUrl: './permission-creator-modal.component.html',
  styleUrls: ['./permission-creator-modal.component.scss'],
})
export class PermissionCreatorModalComponent implements OnDestroy {
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  modalData: PermissionCreatorModalData;
  showSpinner: boolean;

  subscriptions = new Subscription();

  permissions: Permissions = {
    owners: [],
    writers: [],
    readers: [],
  };

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PermissionCreatorModalData,
    private dialogRef: MatDialogRef<PermissionCreatorModalComponent, number | FalkorErrorResponse>,
    private permissionService: PermissionService,
  ) {
    this.modalData = data;
    this.showSpinner = false;
  }

  addUserToRole(role: string, event: MatChipInputEvent) {
    this.permissions[role as Role].push(event.value);
    event.chipInput!.clear();
  }

  removeUserFromRole(role: string, userId: string) {
    this.permissions[role as Role] = this.permissions[role as Role].filter((id) => id !== userId);
  }

  createPermission() {
    this.showSpinner = true;
    this.subscriptions.add(
      this.permissionService.createPermission(this.modalData.datasetId, this.permissions).subscribe({
        next: (response) => {
          this.dialogRef.close(response);
        },
        error: (error: FalkorErrorResponse) => {
          this.dialogRef.close(error);
        },
        complete: () => {
          this.showSpinner = false;
        },
      }),
    );
  }
}
