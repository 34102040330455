import { DocumentLinksInfo } from './document-links';
import { Version } from './version';

export class FalkorDocument extends Version {
  private id: string;
  private body: string | undefined;
  private documentLinksInfo?: DocumentLinksInfo;

  constructor(id: string) {
    super();
    this.id = id;
  }

  getId(): string {
    return this.id;
  }

  getBody(): string | undefined {
    return this.body;
  }

  setBody(body: string) {
    this.body = body;
  }

  getDocumentLinksInfo() {
    return this.documentLinksInfo;
  }

  setDocumentLinksInfo(documentLinksInfo: DocumentLinksInfo) {
    this.documentLinksInfo = documentLinksInfo;
  }
}
