import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginResponse } from '../model/web/login-response';
import { SessionService } from './session.service';
import { Credentials } from '../model/credentials';
import { LoginFormData } from '../model/form/login-form';
import { KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private SESSION_KEY = 'tenant_details';
  public credentials?: Credentials;

  constructor(
    private http: HttpClient,
    private sessionService: SessionService,
    private keycloakService: KeycloakService,
  ) {
    const credentials = this.getAuthSession();
    if (credentials) {
      this.submit(credentials);
      return;
    }
  }
  submit(credentials: Credentials) {
    this.credentials = credentials;
    this.setAuthSession(credentials);
  }

  getHttpHeaders(): HttpHeaders {
    let httpHeaders: HttpHeaders = new HttpHeaders().set('accept', 'application/json');

    if (!this.keycloakService.isLoggedIn()) {
      return httpHeaders;
    }

    httpHeaders = httpHeaders.set('Authorization', `Bearer ${this.keycloakService.getToken()}`);

    if (this.credentials?.userId) {
      httpHeaders = httpHeaders.set('x-user', this.credentials?.userId);
    }

    return httpHeaders;
  }

  private getAuthSession(): Credentials | null {
    return this.sessionService.getItem(this.SESSION_KEY);
  }

  private setAuthSession(credentials: Credentials | null) {
    this.sessionService.setItem(this.SESSION_KEY, credentials);
  }

  private clearAuthSession() {
    this.sessionService.removeItem(this.SESSION_KEY);
  }
}
