import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { Observable } from 'rxjs';
import Audit from '../model/audit';

@Injectable({
  providedIn: 'root',
})
export class AuditService {
  private baseUrl: string;

  constructor(private http: HttpClient, private authService: AuthenticationService) {
    this.baseUrl = `/api/core/v0/${authService.credentials?.tenantId}`;
  }

  private getAuditLog(path: string): Observable<Array<Audit>> {
    const getAuditLogUrl = `${this.baseUrl}/${path}/audit`;

    return this.http.get<Array<Audit>>(getAuditLogUrl, {
      headers: this.authService.getHttpHeaders(),
    });
  }

  getPermissionAuditLog(datasetId: string, permissionId: number) {
    const getPermissionAuditLogUrl = `dataset/${datasetId}/permissions/${permissionId}`;
    return this.getAuditLog(getPermissionAuditLogUrl);
  }

  getDocumentAuditLog(datasetId: string, documentId: string) {
    const getDocumentAuditLogUrl = `dataset/${datasetId}/${documentId}/body`;
    return this.getAuditLog(getDocumentAuditLogUrl);
  }

  getLinkAuditLog(datasetId: string, documentId: string) {
    const getLinkAuditLogUrl = `dataset/${datasetId}/${documentId}/links`;
    return this.getAuditLog(getLinkAuditLogUrl);
  }
}
