import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Link } from '../model/link';
import { DocumentLinksResponse } from '../model/web/document-links-response';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root',
})
export class LinksService {
  private baseUrl: string;

  constructor(private http: HttpClient, private authService: AuthenticationService) {
    this.baseUrl = `/api/core/v0/${authService.credentials?.tenantId}/dataset`;
  }

  getDocumentLinks(datasetId: string, documentId: string, version?: number) {
    const getDocumentLinksUrl = `${this.baseUrl}/${datasetId}/${documentId}/links`;

    let params = new HttpParams();

    if (version) {
      params = params.append('version', String(version));
    }

    return this.http.get<DocumentLinksResponse>(getDocumentLinksUrl, {
      headers: this.authService.getHttpHeaders(),
      params: params,
    });
  }

  updateDocumentLinks(datasetId: string, documentId: string, links: Array<Link>) {
    const addDocumentLinkUrl = `${this.baseUrl}/${datasetId}/${documentId}/links`;

    return this.http.patch(addDocumentLinkUrl, links, {
      headers: this.authService.getHttpHeaders(),
    });
  }

  deleteDocumentLinks(datasetId: string, documentId: string, link: Link) {
    const deleteDocumentLinkUrl = `${this.baseUrl}/${datasetId}/${documentId}/links/${link.datasetId}/${link.documentId}`;

    return this.http.delete(deleteDocumentLinkUrl, {
      headers: this.authService.getHttpHeaders(),
    });
  }
}
