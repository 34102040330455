import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PermissionService } from 'src/app/services/permissions.service';
import { Permissions, Role } from 'src/app/model/permission';
import { ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { FalkorErrorResponse } from 'src/app/model/web/falkor-error-response';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { ConfirmModal } from 'src/app/model/confirm-modal';
import { AuditService } from 'src/app/services/audit.service';
import { HttpErrorResponse } from '@angular/common/http';
import Audit from 'src/app/model/audit';
import { MatTableDataSource } from '@angular/material/table';
import AUDIT_TABLE_HEADERS from 'src/app/model/audit-table-headers';

@Component({
  selector: 'app-view-permission',
  templateUrl: './view-permission.component.html',
  styleUrls: ['./view-permission.component.scss'],
})
export class ViewPermissionComponent implements OnInit, OnDestroy {
  readonly separatorKeysCodes = [ENTER] as const;
  permissionId?: number;
  permissions: Permissions;
  datasetId?: string;
  dialogRef?: MatDialogRef<unknown, unknown>;
  auditLog: Array<Audit>;
  permissionDataSource: MatTableDataSource<Audit>;
  tableColumnHeaders: Array<string>;
  subscriptions = new Subscription();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private permissionService: PermissionService,
    private auditService: AuditService,
    private dialog: MatDialog,
    private snackBarService: SnackbarService,
  ) {
    this.permissions = {
      owners: [],
      writers: [],
      readers: [],
    };

    this.tableColumnHeaders = AUDIT_TABLE_HEADERS;
    this.auditLog = [];
    this.permissionDataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    const params = this.route.snapshot.paramMap;
    this.permissionId = Number(params.get('permissionId'));

    const query = this.route.snapshot.queryParamMap;
    this.datasetId = String(query.get('datasetId'));

    if (!this.permissionId || !this.datasetId) {
      this.router.navigate(['/']);
    }

    this.subscriptions.add(
      this.permissionService.getPermission(this.datasetId, this.permissionId).subscribe((permissions) => {
        this.permissions.owners = permissions.owners;
        this.permissions.writers = permissions.writers;
        this.permissions.readers = permissions.readers;
      }),
    );

    this.getPermissionAudit();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  openConfirmDeleteDialog(role: string, userId: string) {
    const dialogConfig = new MatDialogConfig<ConfirmModal>();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Remove user from Role',
      message: `Are you sure you want to remove ${userId} from ${role}?`,
      action: () => this.removePermission(role as Role, userId),
    };

    this.openConfirmDialog(dialogConfig);
  }

  openConfirmAddDialog(role: string, event: MatChipInputEvent) {
    const userId = event.value;
    if (!userId) {
      this.snackBarService.openSnackBar(false, 'User ID field cannot be empty.');
      return;
    }

    const dialogConfig = new MatDialogConfig<ConfirmModal>();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      title: 'Add user to Permission',
      message: `Are you sure you want to add ${userId} to role ${role}?`,
      action: () => this.updatePermission(role as Role, userId),
    };

    this.openConfirmDialog(dialogConfig);
    event.chipInput!.clear();
  }

  openConfirmDialog<T>(dialogConfig: MatDialogConfig<T>) {
    this.dialogRef = this.dialog.open(ConfirmModalComponent, dialogConfig);
    this.subscriptions.add(
      this.dialogRef.afterClosed().subscribe({
        next: () => {
          this.ngOnInit();
        },
        error: (errorResponse: FalkorErrorResponse) => {
          this.snackBarService.openSnackBar(false, errorResponse.message);
        },
      }),
    );
  }

  private removePermission(role: Role, userId: string) {
    this.subscriptions.add(
      this.permissionService.removePermission(this.datasetId!, this.permissionId!, role as Role, userId).subscribe({
        next: () => {
          this.snackBarService.openSnackBar(true, 'User removed from role');
        },
        error: (errorResponse: FalkorErrorResponse) => {
          this.snackBarService.openSnackBar(false, errorResponse.message);
        },
        complete: () => {
          this.dialogRef?.close();
        },
      }),
    );
  }

  private updatePermission(role: Role, userId: string) {
    this.subscriptions.add(
      this.permissionService.updatePermission(this.datasetId!, this.permissionId!, role as Role, userId).subscribe({
        next: () => {
          this.snackBarService.openSnackBar(true, 'User added to role');
        },
        error: (errorResponse: FalkorErrorResponse) => {
          this.snackBarService.openSnackBar(false, errorResponse.message);
        },
        complete: () => {
          this.dialogRef?.close();
        },
      }),
    );
  }

  private getPermissionAudit() {
    this.subscriptions.add(
      this.auditService.getPermissionAuditLog(this.datasetId!, this.permissionId!).subscribe({
        next: (audit) => {
          this.auditLog = audit;
          this.permissionDataSource.data = this.auditLog;
        },
        error: (errorResponse: HttpErrorResponse) => {
          this.snackBarService.openSnackBar(false, errorResponse.message);
        },
      }),
    );
  }
}
