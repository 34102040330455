export class Version {
  timestamp?: Date;
  versions: number[] = [];

  getLatestVersion(): number {
    return this.versions[this.versions.length - 1];
  }

  getVersion(version: number) {
    return this.versions[version - 1];
  }

  getVersions() {
    return this.versions;
  }

  setVersions(version: number) {
    this.versions = [...Array(version).keys()].map((_, i) => i + 1);
  }

  getTimestamp() {
    return this.timestamp;
  }

  setTimestamp(timestamp: Date) {
    this.timestamp = timestamp;
  }
}
