import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { ListDatasetsComponent } from './components/list-datasets/list-datasets.component';
import { ListDocumentIdsComponent } from './components/list-document-ids/list-document-ids.component';
import { ViewDocumentComponent } from './components/view-document/view-document.component';
import { ViewPermissionComponent } from './components/view-permission/view-permission.component';
import { AuthGuard } from './utility/keycloak.guard';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'datasets',
    component: ListDatasetsComponent,
  },
  {
    path: 'datasets/:datasetId',
    component: ListDocumentIdsComponent,
  },
  {
    path: 'documents/:documentId',
    component: ViewDocumentComponent,
  },
  {
    path: 'permissions/:permissionId',
    component: ViewPermissionComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
