<h2 mat-dialog-title>Document Settings</h2>

<mat-dialog-content>
  <div>
    <mat-slide-toggle [(ngModel)]="editMode" [disabled]="!editToggleEnabled">Edit</mat-slide-toggle>
  </div>
  <div>
    <app-dropdown
      dropdownLabel="Document Body Version"
      [dropdownItems]="bodyVersions.getVersions() | basicDropdownItems"
      [selectedItem]="selectedBodyVersion.value"
      (newSelectedEvent)="selectBodyVersion($event)"
    ></app-dropdown>
  </div>
  <div *ngIf="linkVersions">
    <app-dropdown
      dropdownLabel="Link Version"
      [dropdownItems]="linkVersions.getVersions() | basicDropdownItems"
      [selectedItem]="selectedLinkVersion.value"
      (newSelectedEvent)="selectLinkVersion($event)"
    ></app-dropdown>
  </div>
  <div>
    <app-dropdown
      *ngIf="tagVersions"
      dropdownLabel="Tag Version"
      [dropdownItems]="tagVersions.getVersions() | basicDropdownItems"
      [selectedItem]="selectedTagVersion.value"
      (newSelectedEvent)="selectTagVersion($event)"
    ></app-dropdown>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-raised-button color="primary" (click)="save()">Save</button>
</mat-dialog-actions>
