import { Link } from './link';
import { Tags } from './tags';

export class CreateFalkorDocument {
  data: string;
  documentId?: string;
  permissionId?: number;
  tags?: Tags;
  links?: Array<Link>;

  constructor(data: string, documentId?: string, permissionId?: number, tags?: Tags, links?: Array<Link>) {
    this.documentId = documentId;
    this.permissionId = permissionId;
    this.data = data;
    this.tags = tags;
    this.links = links;
  }
}

export class CreateFalkorDocumentBuilder {
  data: string;
  permissionId?: number;
  documentId?: string;
  tags?: Tags;
  links?: Link[];

  constructor(data: string) {
    this.data = data;
  }

  withDocumentId(documentId: string): CreateFalkorDocumentBuilder {
    this.documentId = documentId;
    return this;
  }

  withPermissionId(permissionId: number): CreateFalkorDocumentBuilder {
    this.permissionId = permissionId;
    return this;
  }

  withTags(tags: Tags): CreateFalkorDocumentBuilder {
    this.tags = tags;
    return this;
  }

  withLinks(links: Link[]): CreateFalkorDocumentBuilder {
    this.links = links;
    return this;
  }

  build(): CreateFalkorDocument {
    return new CreateFalkorDocument(this.data, this.documentId, this.permissionId, this.tags, this.links);
  }
}
