import { FormBuilder, FormControl } from '@angular/forms';

export class UpdateDocumentForm {
  formControl: FormControl;

  constructor(formBuilder: FormBuilder) {
    this.formControl = formBuilder.control('');
  }

  getFormControl() {
    return this.formControl;
  }

  getForm(): string {
    return this.formControl.value;
  }
}
