import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DocumentTagsResponse } from '../model/web/document-tags-response';
import { PutDocumentTagResponse } from '../model/web/put-document-tag-response';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root',
})
export class TagsService {
  private baseUrl: string;

  constructor(private http: HttpClient, private authService: AuthenticationService) {
    this.baseUrl = `/api/core/v0/${authService.credentials?.tenantId}/dataset`;
  }

  getDocumentTagsInfo(datasetId: string, documentId: string, version?: number) {
    const getDocumentTagsInfoUrl = `${this.baseUrl}/${datasetId}/${documentId}/tags/info`;

    let params = new HttpParams();

    if (version) {
      params = params.append('version', String(version));
    }

    return this.http.get<DocumentTagsResponse>(getDocumentTagsInfoUrl, {
      headers: this.authService.getHttpHeaders(),
      params: params,
    });
  }

  updateDocumentTag(datasetId: string, documentId: string, tagKey: string, tagValue: string) {
    const updateDocumentTagUrl = `${this.baseUrl}/${datasetId}/${documentId}/tags/${tagKey}`;

    return this.http.put<PutDocumentTagResponse>(updateDocumentTagUrl, tagValue, {
      headers: this.authService.getHttpHeaders(),
    });
  }

  deleteDocumentTag(datasetId: string, documentId: string, tagKey: string, tagValue: string) {
    const deleteDocumentTagUrl = `${this.baseUrl}/${datasetId}/${documentId}/tags/${tagKey}/${tagValue}`;

    return this.http.delete(deleteDocumentTagUrl, {
      headers: this.authService.getHttpHeaders(),
    });
  }
}
