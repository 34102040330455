import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { DropdownItem } from 'src/app/model/dropdown-item';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent<T> {
  @Input() dropdownItems: DropdownItem<T>[] = [];
  @Input() dropdownLabel: string = 'Select an option';
  @Input() appearance: MatFormFieldAppearance = 'fill';
  @Output() newSelectedEvent = new EventEmitter<T>();
  @Input() selectedItem?: T;

  selectedChanged(value: T) {
    this.newSelectedEvent.emit(value);
  }
}
