import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Link } from '../link';
import { Tags } from '../tags';

export interface CreateDocumentFormData {
  documentId?: string;
  permissionId?: number;
  data: string;
  tags?: Tags;
}

enum CreateDocumentFormKeys {
  data = 'data',
  documentId = 'documentId',
  tags = 'tags',
  tagKey = 'key',
  tagValue = 'value',
  link = 'link',
  linkDatasetId = 'datasetId',
  linkDocumentId = 'documentId',
  permissionId = 'permissionId',
}

export class CreateDocumentFormBuilder {
  formControl: FormGroup;

  constructor(private formBuilder: FormBuilder) {
    this.formControl = this.formBuilder.group({
      [CreateDocumentFormKeys.documentId]: new FormControl({
        value: '',
        disabled: false,
      }),
      [CreateDocumentFormKeys.data]: [''],
      [CreateDocumentFormKeys.link]: this.formBuilder.group({
        [CreateDocumentFormKeys.linkDatasetId]: [''],
        [CreateDocumentFormKeys.linkDocumentId]: [''],
      }),
    });
  }

  withTags() {
    this.formControl.addControl(
      CreateDocumentFormKeys.tags,
      this.formBuilder.group({
        [CreateDocumentFormKeys.tagKey]: [''],
        [CreateDocumentFormKeys.tagValue]: [''],
      }),
    );
    return this;
  }

  withPermissions() {
    this.formControl.addControl(CreateDocumentFormKeys.permissionId, new FormControl(''));
    return this;
  }

  build() {
    return new CreateDocumentForm(this.formControl);
  }
}

export class CreateDocumentForm {
  private formControl: FormGroup;

  constructor(formControl: FormGroup) {
    this.formControl = formControl;
  }

  getForm(): CreateDocumentFormData {
    return this.formControl.value;
  }

  getFormControl() {
    return this.formControl;
  }

  getDocumentIdControl() {
    return this.formControl.get(CreateDocumentFormKeys.documentId);
  }

  getDocumentId(): string {
    return this.getDocumentIdControl()?.value;
  }

  setDocumentId(documentId: string) {
    this.getDocumentIdControl()?.setValue(documentId);
  }

  getDataControl() {
    return this.formControl.get(CreateDocumentFormKeys.data);
  }

  getData(): any {
    return this.getDataControl()?.value;
  }

  setData(data: any) {
    this.getDataControl()?.setValue(data);
  }

  getTagsControl() {
    return this.formControl.get(CreateDocumentFormKeys.tags);
  }

  getTags(): Tags {
    return this.getTagsControl()?.value;
  }

  setTags(tags: Tags) {
    this.getTagsControl()?.setValue(tags);
  }

  getTagKeyControl() {
    return this.getTagsControl()?.get(CreateDocumentFormKeys.tagKey);
  }

  getTagKey(): string {
    return this.getTagKeyControl()?.value;
  }

  setTagKey(key: string) {
    this.getTagKeyControl()?.setValue(key);
  }

  getTagValueControl() {
    return this.getTagsControl()?.get(CreateDocumentFormKeys.tagValue);
  }

  getTagValue(): string {
    return this.getTagValueControl()?.value;
  }

  setTagValue(value: string) {
    this.getTagValueControl()?.setValue(value);
  }

  getLinkControl() {
    return this.getFormControl().get(CreateDocumentFormKeys.link);
  }

  getLink(): Link {
    return this.getLinkControl()?.value;
  }

  getLinkDatasetIdControl() {
    return this.getLinkControl()?.get(CreateDocumentFormKeys.linkDatasetId);
  }

  getLinkDatasetId(): string {
    return this.getLinkDatasetIdControl()?.value;
  }

  setLinkDatasetId(datasetId: string) {
    this.getLinkDatasetIdControl()?.setValue(datasetId);
  }

  getLinkDocumentIdControl() {
    return this.getLinkControl()?.get(CreateDocumentFormKeys.linkDocumentId);
  }

  getLinkDocumentId(): string {
    return this.getLinkDocumentIdControl()?.value;
  }

  setLinkDocumentId(documentId: string) {
    this.getLinkDocumentIdControl()?.setValue(documentId);
  }

  getPermissionIdControl() {
    return this.getFormControl().get(CreateDocumentFormKeys.permissionId);
  }

  getPermissionId() {
    return this.getPermissionIdControl()?.value;
  }

  setPermissionId(permissionId: number) {
    this.getPermissionIdControl()?.setValue(permissionId);
  }
}
