import { Tags } from './tags';
import { Version } from './version';

export class DocumentTags extends Version {
  tags?: Tags;

  setTags(tags: Tags) {
    this.tags = tags;
  }
}
