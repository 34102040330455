import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { DatasetConfig } from '../model/dataset-config';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root',
})
export class DatasetService {
  private baseUrl: string;

  constructor(private http: HttpClient, private authService: AuthenticationService) {
    this.baseUrl = `/api/core/v0/${authService.credentials?.tenantId}`;
  }

  getDatasetInfo(datasetId: string) {
    const getDatasetInfoUrl = `${this.baseUrl}/dataset/${datasetId}/info`;

    return this.http.get<DatasetConfig>(getDatasetInfoUrl, {
      headers: this.authService.getHttpHeaders(),
    });
  }

  getDatasets(): Observable<Array<DatasetConfig>> {
    const getDatasetsUrl = `${this.baseUrl}/dataset/list`;

    return this.http.get<Array<DatasetConfig>>(getDatasetsUrl, {
      headers: this.authService.getHttpHeaders(),
    });
  }
}
