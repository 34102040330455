<mat-dialog-content>
  <h1>Create Permission</h1>
  <div fxLayout="column" fxLayoutGap="1em">
    <mat-form-field *ngFor="let role of permissions | keyvalue" class="form-field" appearance="outline">
      <mat-label>{{ role.key }}</mat-label>
      <mat-chip-list #chipList aria-label="list of user Ids">
        <mat-chip *ngFor="let userId of role.value" (removed)="removeUserFromRole(role.key, userId)">
          {{ userId }}
          <button [disabled]="showSpinner" matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>
        <input
          placeholder="Add user..."
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="addUserToRole(role.key, $event)"
          [disabled]="showSpinner"
        />
      </mat-chip-list>
    </mat-form-field>
  </div>
  <div *ngIf="showSpinner" class="center-spinner">
    <mat-spinner></mat-spinner>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button mat-raised-button color="primary" (click)="createPermission()">Create</button>
  <button mat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
