import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmModal } from 'src/app/model/confirm-modal';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent {
  modalData: ConfirmModal;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmModal) {
    this.modalData = data;
  }
}
