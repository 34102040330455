import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Permissions, Role } from '../model/permission';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  private baseUrl: string;

  constructor(private http: HttpClient, private authService: AuthenticationService) {
    this.baseUrl = `/api/core/v0/${authService.credentials?.tenantId}/dataset`;
  }

  getPermission(datasetId: string, permissionId: number) {
    const getPermissionsUrl = `${this.baseUrl}/${datasetId}/permissions/${permissionId}`;

    return this.http.get<Permissions>(getPermissionsUrl, {
      headers: this.authService.getHttpHeaders(),
    });
  }

  getPermissionIds(datasetId: string) {
    const getPermissionsUrl = `${this.baseUrl}/${datasetId}/permissions`;

    return this.http.get<Array<number>>(getPermissionsUrl, {
      headers: this.authService.getHttpHeaders(),
    });
  }

  createPermission(datasetId: string, permission: Permissions) {
    const createPermissionsUrl = `${this.baseUrl}/${datasetId}/permissions`;

    return this.http.post<number>(createPermissionsUrl, permission, {
      headers: this.authService.getHttpHeaders(),
    });
  }

  updatePermission(datasetId: string, permissiondId: number, role: Role, userId: string) {
    const updatePermissionUrl = `${this.baseUrl}/${datasetId}/permissions/${permissiondId}/${role}/${userId}`;

    return this.http.put(updatePermissionUrl, null, {
      headers: this.authService.getHttpHeaders(),
    });
  }

  removePermission(datasetId: string, permissiondId: number, role: Role, userId: string) {
    const removePermissionUrl = `${this.baseUrl}/${datasetId}/permissions/${permissiondId}/${role}/${userId}`;

    return this.http.delete(removePermissionUrl, {
      headers: this.authService.getHttpHeaders(),
    });
  }
}
