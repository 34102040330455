<mat-card>
  <div fxLayout="row" class="card-header">
    <div>
      <mat-card-title>Dataset {{ datasetIdParam }}</mat-card-title>
      <mat-card-subtitle>
        List of document IDs <span *ngIf="datasetConfig?.documentPermissions">and Permission Ids</span> that belong to
        dataset {{ datasetIdParam }}
      </mat-card-subtitle>
    </div>
    <div fxLayoutGap="1em">
      <button mat-raised-button color="primary" (click)="openCreateDocumentDialog()">Create Document</button>
      <button
        *ngIf="datasetConfig?.documentPermissions"
        mat-raised-button
        color="primary"
        (click)="openCreatePermissionDialog()"
      >
        Create Permission
      </button>
    </div>
  </div>
  <div *ngIf="showSpinner" class="center-spinner spinner-padding">
    <mat-spinner [diameter]="80"></mat-spinner>
  </div>
  <div fxLayout="row">
    <div fxFlex="1 1 auto">
      <mat-list class="ledger-list">
        <div class="mat-list-item header-row">
          <div class="document-header">
            <div class="document-id-header header-sortable" (click)="onHeaderClick('document-id')">
              Document Id
              <span
                class="sort-arrow"
                [ngClass]="{
                  asc: sortType === 'document-id' && sortOrder === 'asc',
                  desc: sortType === 'document-id' && sortOrder === 'desc'
                }"
              ></span>
            </div>
            <div class="timestamp-header header-sortable" (click)="onHeaderClick('timestamp')">
              Timestamp
              <span
                class="sort-arrow"
                [ngClass]="{
                  asc: sortType === 'timestamp' && sortOrder === 'asc',
                  desc: sortType === 'timestamp' && sortOrder === 'desc'
                }"
              ></span>
            </div>
            <div class="version-header header-sortable" (click)="onHeaderClick('version')">
              Version
              <span
                class="sort-arrow"
                [ngClass]="{
                  asc: sortType === 'version' && sortOrder === 'asc',
                  desc: sortType === 'version' && sortOrder === 'desc'
                }"
              ></span>
            </div>
            <div class="transaction-id-header header-sortable" (click)="onHeaderClick('transaction-id')">
              Transaction Id
              <span
                class="sort-arrow"
                [ngClass]="{
                  asc: sortType === 'transaction-id' && sortOrder === 'asc',
                  desc: sortType === 'transaction-id' && sortOrder === 'desc'
                }"
              ></span>
            </div>
          </div>
        </div>
        <mat-nav-list *ngFor="let documentInfo of documentMetaDataResponses; let i = index">
          <div class="document-info" (click)="showDocumentBody(documentInfo.documentId, i)">
            <div class="document-id">{{ documentInfo.documentId }}</div>
            <div class="timestamp">{{ documentInfo.timestamp | date : 'MMM dd, yyyy HH:mm:ss' }}</div>
            <div class="version">{{ documentInfo.version }}</div>
            <div class="transaction-id">{{ documentInfo.transactionId }}</div>
          </div>
          <div
            class="document-body"
            [ngClass]="{ show: documentBodyVisibility[i] }"
            (click)="navigateToDocument(documentInfo.documentId)"
          >
            <p>Body: {{ documentBodies[i] }}</p>
          </div>
        </mat-nav-list>
      </mat-list>
    </div>

    <div *ngIf="datasetConfig?.documentPermissions">
      <h2>Permission Ids</h2>
      <mat-nav-list fxLayoutAlign="start" fxLayout="column">
        <mat-list-item *ngFor="let permissionId of permissionIds" (click)="navigateToPermission(permissionId)">
          <p class="permission-id">{{ permissionId }}</p>
        </mat-list-item>
      </mat-nav-list>
    </div>
  </div>
</mat-card>
