<mat-card *ngIf="document" fxLayout="column" fxLayoutGap="1em">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="header">
    <div>
      <h1>Document {{ document.getId() }}</h1>
      <span>Version {{ document.getLatestVersion() }} ({{ document.getTimestamp() | date : 'medium' }})</span>
    </div>
    <div>
      <button mat-icon-button (click)="openSettingsDialog()"><mat-icon>settings</mat-icon></button>
    </div>
  </div>
  <div fxLayout="row" fxLayoutGap="1em">
    <mat-form-field class="form-field" appearance="outline">
      <mat-label>Links (version {{ selectedLinksVersion }})</mat-label>
      <mat-chip-list #linkList aria-label="Link selection">
        <mat-chip
          class="chip"
          *ngFor="let link of document.getDocumentLinksInfo()?.getLinks()"
          (removed)="openDeleteLinkDialog(link)"
        >
          <a [routerLink]="['/documents', link.documentId]" [queryParams]="{ datasetId: link.datasetId }">
            {{ link.datasetId }}/{{ link.documentId }}
          </a>
          <button matChipRemove *ngIf="editMode">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>
        <input
          *ngIf="editMode"
          placeholder="New link..."
          [matChipInputFor]="linkList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="openAddLinkDialog($event)"
        />
      </mat-chip-list>
    </mat-form-field>

    <mat-form-field *ngIf="documentTags" class="form-field" appearance="outline">
      <mat-label>Tags (version {{ selectedTagVersion }})</mat-label>
      <mat-chip-list #tagList aria-label="tag selection">
        <mat-chip
          class="chip"
          *ngFor="let tag of documentTags?.tags | keyvalue"
          (removed)="openDeleteTagDialog(tag.key, tag.value)"
        >
          {{ tag.key }}:{{ tag.value }}
          <button matChipRemove *ngIf="editMode">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>
        <input
          *ngIf="editMode"
          placeholder="New tag..."
          [matChipInputFor]="tagList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="openAddTagDialog($event)"
        />
      </mat-chip-list>
    </mat-form-field>
  </div>
  <div>
    <mat-expansion-panel hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title> Audit Trail </mat-panel-title>
        <mat-panel-description> Click to view audit trail. </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-tab-group>
        <ng-container *ngFor="let auditTab of auditTabs">
          <mat-tab *ngIf="auditLogHasEntries(auditTab.datasource.data)" [label]="auditTab.name">
            <table mat-table [dataSource]="auditTab.datasource">
              <ng-container matColumnDef="event">
                <th mat-header-cell *matHeaderCellDef>Event</th>
                <td mat-cell *matCellDef="let audit">
                  <strong [className]="audit.event === 'create' ? 'create-event' : 'update-event'">
                    {{ audit.event }}
                  </strong>
                </td>
              </ng-container>
              <ng-container matColumnDef="version">
                <th mat-header-cell *matHeaderCellDef>Version</th>
                <td mat-cell *matCellDef="let audit">
                  {{ audit.version }}
                </td>
              </ng-container>
              <ng-container matColumnDef="timestamp">
                <th mat-header-cell *matHeaderCellDef>Timestamp</th>
                <td mat-cell *matCellDef="let audit">
                  <em>{{ audit.timestamp | date : 'medium' }}</em>
                </td>
              </ng-container>
              <ng-container matColumnDef="transactionId">
                <th mat-header-cell *matHeaderCellDef>Transaction ID</th>
                <td mat-cell *matCellDef="let audit" [matTooltip]="audit.transactionId" matTooltipPosition="left">
                  {{ audit.transactionId.substr(0, 7) }}
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="tableColumnHeaders"></tr>
              <tr mat-row *matRowDef="let row; columns: tableColumnHeaders"></tr>
            </table>
          </mat-tab>
        </ng-container>
      </mat-tab-group>
    </mat-expansion-panel>
  </div>
  <div>
    <mat-form-field #updateForm class="document-body-container" appearance="outline">
      <mat-label>Body (version {{ selectedBodyVersion }})</mat-label>
      <textarea
        matInput
        [formControl]="updateDocumentForm.getFormControl()"
        class="document-body"
        [readonly]="!editMode"
        [value]="document.getBody()"
      ></textarea>
      <button *ngIf="editMode" mat-raised-button class="update-document-btn" (click)="openUpdateDocumentConfirm()">
        Update Document
      </button>
    </mat-form-field>
    <div *ngIf="showSpinner" class="center-spinner">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</mat-card>
