import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';

import { DatasetConfig } from 'src/app/model/dataset-config';
import { DatasetTableRow } from 'src/app/model/dataset-table-row';
import { EncryptionType } from 'src/app/model/encryption-type';
import { TenantId } from 'src/app/model/tenant-id';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DatasetService } from 'src/app/services/dataset.service';

@Component({
  selector: 'app-list-datasets',
  templateUrl: './list-datasets.component.html',
  styleUrls: ['./list-datasets.component.scss'],
})
export class ListDatasetsComponent implements OnInit, OnDestroy {
  datasets: Array<DatasetConfig> = new Array<DatasetConfig>();
  dataSource: MatTableDataSource<DatasetTableRow>;
  displayedColumns: string[];
  tenantId?: TenantId;
  showSpinner: boolean;

  private getDatasetSubscription?: Subscription;

  constructor(public datasetService: DatasetService, public authService: AuthenticationService) {
    this.dataSource = new MatTableDataSource<DatasetTableRow>();
    this.displayedColumns = [
      'datasetId',
      'encryptionType',
      'externalStorage',
      'documentPermissions',
      'documentTags',
      'linkedContract',
    ];

    this.showSpinner = true;

    this.tenantId = authService.credentials?.tenantId;
  }

  ngOnInit(): void {
    this.getDatasetSubscription = this.datasetService
      .getDatasets()
      .subscribe((datasetConfigArray: Array<DatasetConfig>) => {
        this.datasets = datasetConfigArray;

        // Build table rows using array
        let datasetTableRowArray: Array<DatasetTableRow> = [];
        datasetConfigArray.forEach((datasetConfig) => {
          datasetTableRowArray.push(this.buildTableRowFromObject(datasetConfig));
        });
        this.dataSource.data = datasetTableRowArray;

        this.showSpinner = false;
      });
  }

  ngOnDestroy(): void {
    this.getDatasetSubscription?.unsubscribe();
  }

  private buildTableRowFromObject(datasetConfig: DatasetConfig): DatasetTableRow {
    const encryptionType: string = this.getEncryptionType(
      datasetConfig.platformEncryption,
      datasetConfig.thresholdEncryption,
    );
    return new DatasetTableRow(
      datasetConfig.datasetId,
      encryptionType,
      datasetConfig.externalStorage,
      datasetConfig.documentPermissions,
      datasetConfig.documentTags,
      datasetConfig.linkedContract,
    );
  }

  private getEncryptionType(platformEncryption: boolean, thresholdEncryption: boolean): string {
    let encryptionType: string = '';

    if (!platformEncryption && !thresholdEncryption) {
      encryptionType = EncryptionType.None;
    } else if (platformEncryption && !thresholdEncryption) {
      encryptionType = EncryptionType.Platform;
    } else if (!platformEncryption && thresholdEncryption) {
      encryptionType = EncryptionType.Threshold;
    } else {
      encryptionType = EncryptionType.Unkown;
    }

    return encryptionType;
  }

  getCssClass(encryptionType: string): string {
    let cssClass: string = '';

    if (encryptionType === EncryptionType.Platform) {
      cssClass = 'blue-text';
    } else if (encryptionType === EncryptionType.Threshold) {
      cssClass = 'yellow-text';
    } else {
      cssClass = 'gray-text';
    }
    return cssClass;
  }
}
