<mat-form-field [appearance]="appearance">
  <mat-label>{{ dropdownLabel }}</mat-label>
  <mat-select [(value)]="selectedItem">
    <mat-option
      *ngFor="let dropdownItem of dropdownItems"
      [value]="dropdownItem.value"
      (click)="selectedChanged(dropdownItem.value)"
    >
      {{ dropdownItem.viewValue }}
    </mat-option>
  </mat-select>
</mat-form-field>
