import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { KeycloakService, KeycloakEventType } from 'keycloak-angular';
import { LoginForm } from '../../model/form/login-form';
import { FormBuilder } from '@angular/forms';
import { Credentials } from '../../model/credentials';
import { AuthenticationService } from '../../services/authentication.service';
import { SnackbarService } from '../../services/snackbar.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  private loginSubscription?: Subscription;
  loginForm: LoginForm;

  constructor(
    private formBuilder: FormBuilder,
    private keycloakService: KeycloakService,
    private router: Router,
    private authService: AuthenticationService,
    private snackbarService: SnackbarService,
  ) {
    this.loginForm = new LoginForm(formBuilder);
  }

  ngOnInit(): void {
    // Subscribe to the 'keycloak' event
    this.loginSubscription = this.keycloakService.keycloakEvents$.subscribe((event) => {
      if (event.type == KeycloakEventType.OnTokenExpired) {
        this.keycloakService.updateToken(10);
      }
    });
  }

  ngOnDestroy(): void {
    this.loginSubscription?.unsubscribe();
  }

  submit() {
    const tenantId = this.loginForm.getTenantId().id;

    if (!tenantId) {
      // No tenant ID found, display a Snackbar with an error message
      this.snackbarService.openSnackBar(false, 'No tenant ID found. Please enter a valid tenant ID.');
      return; // Return early to prevent further execution
    }
    const credentials: Credentials = {
      tenantId: tenantId,
      userId: this.loginForm.getUserId(),
    };
    this.authService.submit(credentials);
    console.log(credentials);
    this.router.navigate(['/datasets']);
  }
}
